@import "utilities/calendar";
@import "utilities/date-picker";


// custom
.txt-12 {
  font-size: 12px;
}

.txt-14 {
  font-size: 14px;
}
.txt-16 {
  font-size: 16px;
}
.txt-18 {
  font-size: 18px;
}
.txt-20 {
  font-size: 20px;
}


.underline-hover:hover {
  text-decoration: underline;
}
body > iframe[style*='2147483647']{
  display: none;
}

.btn-white {
  background: white !important;
  &:hover {
    color: springgreen !important;
  }
}
.min-h-2 {
  min-height: 20px !important;
}

.nav-item {
  .nav-link {
    svg {
      filter: grayscale(100%);
    }

    &.active,
    &:hover {
      svg {
        filter: grayscale(0);
      }
    }
  }
}

.svg-icon {
  &.grayscale {
    filter: grayscale(100%);

    &:hover {
      filter: grayscale(0);
    }
  }
}

.menu-nav {
  .menu-item {
    svg {
      filter: grayscale(100%);
    }

    &.menu-item-active,
    &:hover {
      svg {
        filter: grayscale(0);
      }
    }
  }
}

.color-white {
  color: white !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #6cc049 !important;
}

.menu-title {
  padding: 0 25px;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text,
.aside-menu
.menu-nav
> .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
> .menu-heading
.menu-text,
.aside-menu
.menu-nav
> .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
> .menu-link
.menu-text {
  color: black !important;
  font-weight: 600;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  font-size: 14px;
}

.aside-left {
  ul {
    li {
      .btn-icon {
        width: auto !important;
        height: auto !important;
        min-height: calc(1.5em + 1.65rem + 2px);
        min-width: calc(1.5em + 1.65rem + 2px);
        margin: 0 auto;
        display: block;
      }
    }
  }
}

.aside-footer, .aside-nav{
  width: 100%;
  padding: 0 8px;
  ul{
    width: 100%;
  }
  .nav-link, #kt_quick_user_toggle {
    width: 100% !important;
    height: 100% !important;
  }
}

.aside-footer {
  border-top: 1px solid #D1D1D1;
}

.badge {
  min-width: 84px;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: normal;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;

  &.badge-primary {
    background: #6cc049;
    border: 1px solid #6cc049;
    color: white;
  }

  &.badge-warning {
    background: #f29f05;
    border: 1px solid #f29f05;
    color: white;
  }

  &.badge-danger {
    background: #cc4435;
    border: 1px solid #cc4435;
    color: white;
  }

  &.badge-registered {
    background: #BF5B04;
    border: 1px solid #BF5B04;
    color: white;
  }

  &.badge-rescheduled {
    background: #BF5B04;
    border: 1px solid #BF5B04;
    color: white;
  }

  &.badge-canceled {
    background: #808080;
    border: 1px solid #808080;
    color: white;
  }

  &.badge-approved {
    background: #5EC8F2;
    border: 1px solid #5EC8F2;
    color: white;
  }
}

.btn {
  @media only screen and (min-width: 769px) {
    padding: 6px 24px;
    font-size: 14px;
    &.rounded {
      border-radius: 18px !important;
    }
  }

  &.btn-secondary {
    color: #6cc049;
    background: none;
    border: 1px solid #6cc049;

    &:hover {
      color: #6cc049 !important;
      border: 1px solid #6cc049 !important;
      background: #e9ffdf !important;
    }
  }

  &.btn-sm {
    font-size: 0.925rem;
  }

  &.btn-secondary-info {
    color: #808080;
    background: none;
    border: 1px solid #808080;

    &:hover {
      color: #6cc049 !important;
      border: 1px solid #6cc049 !important;
      background: #e9ffdf !important;
    }
  }
}

.my-auto {
  margin: 0 auto;
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}

// filter

.filter-container {
  // Search
  .MuiPaper-rounded.search-atom {
    display: flex;
    align-items: center;
    width: 365px;
    padding: 4px 24px;
    border-radius: 50px !important;
    box-shadow: none !important;
    border: 1px solid #e0e0e0;
    @media only screen and (max-width: 471px) {
      width: 100%;
    }

    &_disabled {
      background-color: #D1D1D1;
      display: flex;
      align-items: center;
      width: 365px;
      padding: 4px 24px;
      border-radius: 50px !important;
      box-shadow: none !important;
      border: 1px solid #e0e0e0;
    }

    .search-atom-input {
      margin-left: 8px;
      // flex: 1;
      width: 100%;
      font-size: 14px;
      color: #3f4254;
      border: 0;
      box-shadow: none;

      &:focus-visible {
        color: #3f4254;
        outline: none;
      }
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #e0e0e0;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e0e0e0;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #e0e0e0;
    }

    .search-atom-button,
    .clear-atom-button {
      padding: 4px;
    }

    .divider {
      width: 1px;
      height: 28px;
      margin: 4px;
    }
  }

  .filter-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .reset-filter {
      letter-spacing: 0.015em;
      text-decoration-line: underline;
      text-transform: capitalize;
      font-size: 14px;
      line-height: 130%;
      color: #10A8E5;
    }

    &.open {
      .react-reveal {
        z-index: 10;
      }
    }
  }

  .filter-card {
    display: flex;
    align-items: center;
    width: auto;
    cursor: pointer;
    padding: 4px 24px;
    border-radius: 50px !important;
    box-shadow: none !important;
    border: 1px solid #e0e0e0;

    &_disabled {
      background-color: #D1D1D1;
      display: flex;
      align-items: center;
      width: auto;
      cursor: pointer;
      padding: 4px 24px;
      border-radius: 50px !important;
      box-shadow: none !important;
      border: 1px solid #e0e0e0;

      h4 {
        margin: 0;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 160%;
        margin-left: 24px;
        margin-right: 12px;
        font-weight: 400;
      }
    }

    h4 {
      margin: 0;
      letter-spacing: 0.01em;
      color: #808080;
      font-size: 14px;
      line-height: 160%;
      margin-left: 24px;
      margin-right: 12px;
      font-weight: 400;
    }

    .flag-orange {
      width: 12px;
      height: 12px;
      background: #f29f05;
      border-radius: 50%;
    }

    .filter-icon {
      width: 32px;
      height: 32px;
      filter: grayscale(100%);
      padding: 6px;
    }

    .card-label {
      margin-bottom: 0;
    }

    &.active {
      background: #eff8ed;
      border: 1px solid #6cc049;

      h4 {
        color: #6cc049;
      }

      .filter-icon {
        filter: grayscale(0%);
      }
    }
  }

  .react-reveal {
    position: absolute;
    top: 85px;
    right: 0px;
    transition: 0.5s;
  }

  .filter-abs {
    cursor: default;
    width: 340px;
    height: auto;
    z-index: -1;
    box-shadow: 0px -1px 6px rgb(0 0 0 / 9%),
    2px 10px 16px -2px rgb(0 0 0 / 10%);

    &.active {
      margin-top: -85px;
      z-index: 10;
    }

    .card-header {
      .d-flex {
        width: 100%;
      }

      .filter-icon {
        width: 36px;
        height: 36px;
      }
    }

    .filter-body {
      overflow-y: auto;
      max-height: 60vh;

      .gutter-b {
        margin-bottom: 0;
      }

      &.overflow-none {
        overflow-y: unset;
        max-height: 90vh;
      }
    }
  }
}

.upload-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    font-size: 16px;
    margin-top: 32px;
    max-width: 280px;
    font-weight: 600;
    color: #808080;

    span {
      color: #10a8e5;
    }
  }
}

.card {
  &.bg-secondary {
    background-color: #f5f5f5 !important;
    border-radius: 16px !important;
  }

  .images-card {
    padding: 30px;
    height: 280px;
    max-height: 442px;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    object-fit: cover;
  }

  .card-body {
    .images-card {
      padding: 0px;
    }
  }
}

.card {
  .images-carousel-card {
    padding: 15px 24px;
  }

  .card-body {
    .images-carousel-card {
      padding: 0px;
    }
  }
}

.table th {
  padding: 25px 0.75rem;
}

.table td {
  padding: 28px 0.75rem;
}

.table {
  .bg-gray {
    background: #F8F8F8;
  }
}

.btn-react-multi-carousel-arrows-right {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
  cursor: pointer;
  top: 50%;
}

.btn-react-multi-carousel-arrows-right::before {
  content: "\e825";
  font-weight: bold;
}

.react-multi-carousel-arrows-right {
  right: 0%;
}

.btn-react-multi-carousel-arrows-left {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
  cursor: pointer;
  top: 50%;
}

.btn-react-multi-carousel-arrows-left::before {
  content: "\e824";
  font-weight: bold;
}

.react-multi-carousel-arrows-left {
  left: 0%;
}

.react-multi-carousel-arrows {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 50px;
  opacity: 1;
  height: 100%;
  cursor: pointer;
}

.custom-dot-multi-carousel-active {
  width: 16px;
  height: 16px;
  background: #1b1b1b;
  border-radius: 100%;
}

.custom-dot-multi-carousel-inactive {
  width: 16px;
  height: 16px;
  background: rgba(53, 53, 53, 0.4);
  border-radius: 100%;
}

.modal-image .modal-header .close span {
  display: block !important;
}

.modal-header{
  .close{
    width: 24px;
    height: 24px;
    span{
      font-size: 40px;
      font-weight: 300 !important;
    }
  }
}

.modal-main .modal-header .close span {
  display: block !important;
}

.modal-main {
  .modal-header {
    h2 {
      margin-bottom: 0 !important;
    }

    .close {
      font-size: 32px;
    }
  }
}

.swal-text {
  text-align: center;
  color: #353535 !important;
  font-size: 16px;
}
.swal-title {
  font-size: 16px;
}

.swal-footer {
  display: flex;
  justify-content: center;

  .swal-button-container {
    flex: 1;

    .swal-button {
      width: 100%;
      border: 0;

      &.swal-button--confirm {
        background-color: #6cc049;
      }
    }
  }
}

.swal-text:last-child {
  margin-bottom: 0 !important;
}

.swal-text:first-child {
  margin-top: 0 !important;
}

.img-box {
  overflow: hidden;
}

img.disable {
  cursor: default !important;
  background: #efefef !important;
}

.label-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.label-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.label-container input:checked ~ .checkmark {
  background-color: #6cc049;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label-container .checkmark:after {
  left: 7px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the checkmark/indicator minus */
.label-container-group .checkmark:after {
  left: 7px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 5px 0 0 5px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

button {
  &:disabled {
    cursor: default !important;
  }
}

.btn.btn-primary.disabled, .btn.btn-primary:disabled {
  color: #FFFFFF;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}

.btn.btn-link.disabled, .btn.btn-link:disabled {
  color: #D1D1D1;
  span{
    color: #D1D1D1;
  }
  img{
    filter: grayscale(100%);
    opacity: .4;
  }
}

//selectButton
.label-container-button {
  .label-button {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: #808080;
    padding: 8px 16px;
    border: 1px solid #D1D1D1;
    border-radius: 38px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.label-container-button input:checked ~ .label-button {
  color: #6CC049;
  border-color: #6CC049;
  background: #EFF8ED;
}

.label-button:hover {
  color: #6CC049;
  border-color: #6CC049;
  background: #EFF8ED;
}


.tabIcon {
  box-shadow: none !important;
  border-bottom: 1px solid #EBEDF3;

  .MuiPaper-elevation4 {
    box-shadow: none !important;
  }

  .MuiTab-wrapper {
    flex-direction: row-reverse;
  }

  .MuiTab-labelIcon[aria-selected='false'] {
    svg {
      filter: grayscale(100%);
    }
  }
}

a.link {
  color: #5EC8F2;
  text-decoration-line: underline;
  font-weight: 600;
}

.reject-list-card {
  background: #FAECEB;
  border: 1px solid #C2C2C2;

  .card-header {
    background-color: #FAECEB;
    border-bottom: 1px solid #D9D9D9;

    h3 {
      font-size: 1.275rem;
    }
  }
}

.reason-list-card {
  background: #F8F8F8;
  border: 1px solid #C2C2C2;

  .card-header {
    background-color: #F8F8F8;
    border-bottom: 1px solid #D9D9D9;

    h3 {
      font-size: 1.275rem;
    }
  }
}

.sweet-outline-success {
  border: 1px solid #6CC049 !important;
  color: #6CC049;
  background-color: #fff;
}

.sweet-outline-success:hover {
  border: 1px solid #6CC049 !important;
  color: #FFF;
  background-color: #6CC049 !important;
}

.sweet-success {
  border: 1px solid #6CC049 !important;
  background-color: #6CC049;
  color: #FFF !important;
}

.sweet-success:hover {
  border: 1px solid #6CC049 !important;
  color: #FFF;
  background-color: #6CC049 !important;
}

//tooltip
.MuiTooltip-tooltipPlacementBottom {
  font-size: 14px;
}

//button filter lahan
.btn-filter {
  padding-left: 0;

  .checkmark {
    display: none;
  }

  .label-filter {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #808080;
    padding: 12px 21px;
    border: 1px solid #D1D1D1;
    border-radius: 38px;
    background-color: white;

  }
}

.label-container input:checked ~ .label-filter {
  background-color: #EFF8ED;
  color: #6CC049;
  border-color: #6CC049;
}

.label-container .icon-checkbox-label {
  width: 18px;
  height: 18px;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.color-black {
  color: black;
}

.color-gray {
  color: #808080
}
.color-neutral {
  color: #353535;
}

.color-red{
  color: #CC4435;
}

#react-select-3-listbox{
  z-index: 10 !important;
}

.space-1 {
  letter-spacing: 0.01em;
}

.tabAgenda {
  box-shadow: none !important;
  background-color: unset !important;

  .MuiTabs-indicator {
    display: none;
  }

  .MuiPaper-elevation4 {
    box-shadow: none;
  }

  .MuiTab-root {
    border-radius: 38px;
    padding: 12px 16px;
    border: 1px solid #D1D1D1;
    margin-right: 24px;
    text-transform: capitalize;
    letter-spacing: 0.01em;
    font-weight: 400;
    font-size: 14px;
    background-color: white;
  }

  .Mui-selected {
    border: 1px solid #6cc049;
    background-color: #EFF8ED;
  }
}

.cluster {
  div {
    color: white !important;
    font-size: 12px !important;
    width: 52px !important;
    line-height: 52px !important;
  }
}

// Switch Toogle

.switch-toggle {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
}

.switch-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-switch-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-switch-toggle:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-switch-toggle {
  background-color: #6CC049;
}

input:focus + .slider-switch-toggle {
  box-shadow: 0 0 1px #6CC049;
}

input:checked + .slider-switch-toggle:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.slider-switch-toggle.round {
  border-radius: 34px;
}

.slider-switch-toggle.round:before {
  border-radius: 50%;
}

.bg-gray-card {
  background-color: #F8F8F8;
}

.collaps-rotate {
  transition: all 0.3s;

  &.rotate {
    transform: rotate(180deg);
  }
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiTable-root {
  .MuiTableCell-root {
    border-bottom: none !important;
  }

  td {
    padding-left: 32px;
  }
}

.selected-file {
  .card {
    img {
      max-height: 320px;
    }
  }
}

.border-dashed {
  border: 1px dashed #B2B2B2 !important;

  img {
    height: 150px !important;
    width: auto !important;
  }
}

.withoud-shadow {
  box-shadow: none !important;
}

.color-blue {
  color: #5EC8F2 !important;
}

.underline {
  text-decoration: underline !important;
}

.remove-upload {
  z-index: 10;
  position: absolute;
  right: 24px;
  top: 36px;
}

.feedback {
  color: #F64E60 !important;
}

.button-disabled {
  background-color: #D1D1D1;
  color: #fff
}

.button-disabled:hover {
  background-color: #bdbbbb;
  color: #fff;
}

.input-eye-container {
  margin-left: -30px;
  z-index: 999;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.is-invalid-toggle {
  border-color: #F64E60;
  padding-right: calc(1.5em + 1.3rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.is-valid-toggle {
  border-color: #3699FF;
  padding-right: calc(2.5em + 1.3rem);
  background-repeat: no-repeat;
  background-position: right calc(2.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

//log
.body-log {
  min-height: 570px;
}

.log-item {
  padding-left: 20px;
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    display: block;
    z-index: 10;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    top: calc(50% - 5px);
    background: #808080;
    border-radius: 50%;
  }

  &:after {
    content: '';
    display: block;
    z-index: 10;
    height: 30px;
    position: absolute;
    left: 4px;
    top: calc(50% + 5px);
    border: 1px dashed #D1D1D1;
  }

  &:last-child {
    &:after {
      border: 0;
      height: 0;
    }
  }
}

.select-date {
  width: 290px;
  height: auto;
  padding: 12px 12px;
  border-radius: 6px;
  border: 1px solid #E4E6EF;
  background: white;
  &.full {
    width: 100%;
  }
}

.date-picker-custom {
  position: absolute;
  right: 0;
  top: -20px;
  z-index: 10;
  background: white;
  border: 1px solid #D1D1D1;
  box-sizing: border-box;
  box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.09), 2px 10px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrStaticRange {
    border-bottom: 0;
  }

  .rdrStaticRangeSelected {
    color: #6CC049 !important;
  }

  .rdrStartEdge, .rdrInRange, .rdrEndEdge {
    color: #6CC049 !important;
  }

  .rdrInRange {
    color: #EFF8ED !important;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #808080 !important;
  }

  .rdrDayHovered, .rdrDayStartPreview, .rdrDayEndPreview, .rdrDayInPreview {
    border-color: #6CC049 !important;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background: #6CC049 !important;
  }

  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    background: white !important;
  }
}

.breadcrumb .MuiTypography-colorTextPrimary {
  font-weight: 500 !important;
  text-transform: capitalize;
}
.MuiTypography-body1{
  font-family: 'Work Sans', sans-serif !important;
}

.alert {
  margin: 0;
  padding: 0;
  width: fit-content;

  .alert-item {
    padding: 18px 26px;
    border-radius: 10px;

    svg {
      width: 19px;
      height: 19px;
      margin-right: 26px !important;
    }

    .mr-5 {
      margin-right: 0 !important;
    }

    &.alert-warning {
      background-color: #FFF9DA;
      border-color: unset;

      div {
        color: #FFA800 !important;
      }
    }

    &.alert-info {
      background-color: #F8F8F8;
      border-color: unset;

      div {
        color: #353535 !important;
      }
    }

    &.alert-success, &.alert-completed {
      background-color: #E3FBFF !important;
      border-color: unset;

      div {
        color: #353535 !important;
      }
    }
  }
}

.card {
  .card-header {
    &.capitalize {
      .card-label {
        text-transform: capitalize;
      }
    }
  }
}

//loading
.dialog-loader.dialog-shown.dialog-loader-full {
  width: 100%;
  height: 100%;
  left: 0;
  align-items: center;
  background-color: #3f42548f;
  color: white;
  margin-left: 0;
  top: 0;
}

#kt_login {
  .login-aside {
    .login-image-bg {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    @media only screen and (max-width: 884px) {
      margin: auto;
      .login-image-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}

.CkEditor {
  .ck.ck-editor__editable_inline {
    min-height: 200px;
  }
}

.mw-500 {
  max-width: 500px;
}

.elipsis-1 {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}


.accordion_bulk_approve_order {
  .accordion {
    .card {
      border: none !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.11) !important;

    }
  }
}

.cursor-pointer * {
  cursor: pointer !important;
}


.input[type='checkbox'] {
  cursor: pointer !important;
}

.table_hidden_bottom {

  .d-flex.justify-content-between.align-items-center.flex-wrap {
    display: none !important;
  }

}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background: white !important;
    padding: 10px 20px !important;
    color: black !important;
    border-radius: 9999px !important;
    border: 1px solid #dedede;
    box-shadow: 1px 1px 50px 10px rgba(202, 198, 198, 0.20);
    font-size: 16px;
    text-align: center;
  }
}

.kur-bni {
  margin-left: 16px;

  &.detail {
    background: #F8F8F8;
  }

  &.list {
    background: none;
  }

  width: 50px;
  height: auto;
  display: block;
  padding: 4px 8px 4px 8px;
  background: #F8F8F8;
  border-radius: 4px;

  img {
    width: 100%;
    height: auto;
  }
}

.flex-1 {
  flex: 1
}

.none_capitalize {
  text-transform: none;
}

.status-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  &.warning, &.WAITING, &.PENDING, &.SUBMITTED {
    color: #F29F05;
  }

  &.danger, &.REJECTED, &.rejected {
    color: #CC4435;
  }

  &.CANCELED{
    color: #808080;
  }

  &.need-ba, &.NEED_BA, &.RESCHEDULED {
    color: #BF5B04;
  }

  &.completed, &.COMPLETED, &.CONFIRMED_FO {
    color: #6CC049;
  }

  &.approved, &.APPROVED {
    color: #5EC8F2;;
  }
}

.card_activity_history_customs {
  width: 100%;

  .d-flex.mr-3.align-items-center {
    width: 100%;

    .d-flex.text-dark-75.font-size-h5.font-weight-bold.mr-3 {
      width: 100%;

      .d-flex.align-items-start.justify-content-between {
        width: 100%;
      }
    }
  }
}

.button-with-icon {
  display: flex;
  align-items: center;
  padding: 10px 24px;
  border-radius: 8px;
  border: 1px solid #D1D1D1;
  width: fit-content;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background-color: #F1FFEB;
  }

  .span {
    color: #6CC049 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  &.disabled {
    background-color: #D1D1D1;
    cursor: not-allowed;
  }
}

.max-height-50 {
  max-height: 50vh !important;
}

.fit-content {
  width: fit-content;
}

.hidden {
  display: none;
}

.w-full {
  width: 100% !important;
}
.w-fit{
  width: fit-content;
}
.h-full {
  height: 100% !important;
}
.h-min-screen {
  min-height: 100vh;
}

.add-image-list {
  width: 128px;
  height: 128px;

  .dropzone, .dropzone-have-value {
    width: 128px;
    height: 128px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.file-preview {
  position: absolute;
  top: calc(50% + 20px - 1.25rem);
  left: 50%;
  width: 78%;
  transform: translateX(-50%) translateY(-50%);
}

.card-image-preview {
  overflow: hidden;
  width: 128px;
  height: 128px;
  border-radius: 10px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-hover {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #00000082;
    transition: .3s;

    svg {
      cursor: pointer;
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  &:hover {
    .card-hover {
      display: block;
    }
  }
}

.select-language {
  .btn {
    background-color: transparent;
    border: none;
    box-shadow: none;
    transition: .5s;
    width: fit-content;

    &.active {
      background-color: #8bcd6f;
      color: white;
    }
  }
}

.btn-white {
  background: white !important;
  border: 1px solid #D1D1D1 !important;
  color: #808080 !important;
}


.file-uploader-container-so {
  height: 220px;
  border: 2px dashed #b2b2b2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s;
  cursor: pointer;
  border-radius: 8px;

  &.selected-file-style {
    cursor: default !important;
  }

  &.hover-selected:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &.on-drag-file {
    background: rgba(0, 0, 0, 0.05);
  }

  &.error-file-container {
    background: rgba(255, 0, 0, 0.05) !important;
  }
}

.form-check{
  .form-check-label{
    margin-left: 16px;
  }
}

.MuiBackdrop-root {
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 100!important;
}

.add-component{
  position: fixed;
  left: -390px;
  bottom: 100px;
  z-index: 100;
  background: white;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 1px 7px 14px -2px rgba(0, 0, 0, 0.12), 0px -1px 4px 0px rgba(0, 0, 0, 0.06);
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s !important;

  &.active {
    left: 100px;
    opacity: 1;
    transition: opacity 0.5s, visibility 0.5s !important;
  }

  .icon-close {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .card-body{
    padding: 16px !important;
  }

  .menu-item{
    span{
      font-size: 12px !important;
    }
    &:hover{
      transition: .3s;
      background: #F3F3F3;
      border-radius: 8px;
      .title{
        transition: .3s;
      }
    }
  }
}

.divider{
  width: 100%;
  height: 1px;
  border-top: 1px solid #D1D1D1;
}

.aside-hidden{
  .aside{
    display: none !important;
  }
  .wrapper{
    padding: 0 !important;
  }
  .subheader{
    display: none !important;
  }
  .container{
    padding: 0 !important;
    max-width: 100% !important;
  }
  .footer{
    padding-left: 16px;
  }
}

.stepper{
  .step{
    width: 24px;
    height: 24px;
    border-radius: 100%;
    color: white;
    background-color: #808080;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active{
      background-color: #6CC049;
    }
  }
  .line{
    width: 18px;
    height: 1px;
    background-color: #808080;
    margin: 0 16px;
  }
  .text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.21px;
    color: #808080;
    white-space: nowrap;
  }
}

.circle{
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #D1D1D1;
}

.min-h-submission {
  min-height: calc(100vh - 270px);
}

.max-h-submission {
  max-height: calc(100vh - 188px);
}

.max-h-280px{
  max-height: 280px;
}

.label-map{
  font-weight: bold;
  color: black !important;
  //text-shadow: 0 0 3px #000000 !important;
  font-size: 12px !important;
}

.expand-map{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: white;
  border-radius: 0 10px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 60px;
}

.loading-map{
  position: absolute;
  display: flex;
  align-items: center;
  color: white;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 20;
}

.floating-select-area{
  background-color: #353535;
  padding: 18px 34px;
  border-radius: 6px;
  width: 94%;
  position: fixed;
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.09), 2px 10px 16px -2px rgba(0, 0, 0, 0.10);
}

.Toastify__toast-container--top-center{
  width: auto !important;
  .Toastify__toast-body{
    margin-right: 54px;
  }
  .Toastify__close-button{
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    right: 12px;
  }
}

.w-80vw{
  width: 80vw;
}

.alert-info{
  border-radius: 10px;
  &.bg-gray{
    background-color: #F8F8F8;
  }
  &.bg-success{
    background-color: #ceffba;
  }
  &.bg-warning{
    background-color: #FFF9DA !important;
    color: #F29F05;
    border: 1px solid #F29F05;
  }

}

.modal-map{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  z-index: 100;
  &.active {
    display: block;
  }

  .btn-back{
    position: absolute;
    z-index: 101;
    left: 34px;
    top: 80px;
    border-radius: 36px;
    padding: 8px 16px;
  }
}

.gm-style-moc {
  height: unset !important;
}
.gm-style .gm-style-iw-c{
  padding: 0 !important;
}

.info-windows {
  min-width: 250px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.border-0{
  border: 0 !important;
}

.table{
  margin-bottom: 0 !important;
}


.bg-danger-subtle{
  background-color: #FAECEB !important;
}

.table-fixed{
  table-layout: fixed;
}

table.border{
  th, td {
    border: 1px solid #181C32;
    padding: 4px 12px;
  }
}


.w-75{
  width: 75%;
}
.w-50{
  width: 50%;
}
.w-30{
  width: 30%;
}
.w-25{
  width: 25%;
}
.w-20{
  width: 20%;
}
.w-10{
  width: 10%;
}

.form-control[readonly] {
  background-color: #F8F8F8 !important;
}
.MuiButton-root{
  font-family: "Work Sans", sans-serif !important;
}
.MuiButton-textSecondary{
  color: #5EC8F2 !important;
}
.MuiButton-colorInherit{
  color: #808080 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked{
  color: #6CC049 !important;
  svg{
    color: #6CC049 !important;
  }
}