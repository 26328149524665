//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//


// Functions
@import "base/functions";

// Mixins
@import "base/mixins";
@import "components/mixins";
@import "vendors/plugins/mixins";

// Bootstrap mixins
@import "~bootstrap/scss/_mixins.scss";

// Global variables
@import "components/variables.demo";
@import "components/variables.bootstrap";
@import "components/variables.custom";

// 3rd-Party plugins variables
@import "vendors/plugins/variables";

// Theme layout variables
@import "layout/variables";

.dropzone-have-error {
  text-align: center;
  padding: 7em;
  border: 1px dashed #F64E60;
  //background-color: #ffe3e7;
  color: #F64E60;
  cursor: pointer;
}
.dropzone-have-value {
  text-align: center;
  padding: 7em;
  border: 1px dashed #E4E6EF;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
}
.dropzone {
  text-align: center;
  padding: 7em;
  border: 1px dashed #E4E6EF;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
}
