.calendar-layout {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));

  .bg-calendar-box-customs {
    background-color: #f8f8f8;
  }

  .calendar_data_badge {
    width: 3px;
    border-radius: 10px;
    height: auto;
  }


  .text_more_calendar_box {
    cursor: pointer;
    color: #5EC8F2;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.modal_detail_team_customs_detail_schedule {
  width: 1085px !important;
}

.modal_detail_team_customs {
  max-height: 700px !important;
  overflow-y: auto;

  .modal-header {
    font-size: 24px !important;
    width: 800px;
    z-index: 100;
    background-color: white;
    position: fixed;

    button {
      span {
        font-size: 38px !important;
      }
    ;
    }
  }


  .modal-body {
    padding-top: 100px !important;

    .accordion .card {
      border: none;
      border-bottom: 1px solid #D1D1D1;
    }

    .table_list_popup_schedule {
      text-align: start !important;
    }
  }
}



.calendar-layout {
  .custom_height_calender_overflow {
    height: 400px !important;
  }

  .position-relative {
    .popup_detail_calendar {
      position: absolute;
      left: 10px;
      top: 0;
      transform: translateY(-400px);

    }

    &:nth-child(7n - 1),
    &:nth-child(7n) {
      .popup_detail_calendar {
        position: absolute;
        top: 0;
        transform: translateY(-160px);
        left: unset;
        right: 0;
      }
    }
  }

}
