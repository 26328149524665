//
// Print Mode
//

@media print {
    .pagebreak { page-break-before: always; }
    body{
        height: fit-content !important;
        background: white !important;
    }
    .burger-icon.burger-icon-left{
        display: none;
    }
    #zohohc-asap-web-launcherbox{
        display: none !important;
    }
    .print-content-only {
        padding: 0 !important;
        background: none !important;
        .wrapper,
        .page,
        .content,
        .container,
        .container-fluid {
            background: none !important;
            padding: 0 !important;
            margin: 0 !important;
        }
        .aside,
        #kt_header_mobile,
        .sidebar,
        .sticky-toolbar,
        .scrolltop,
        .header,
        .header-mobile,
        .footer,
        .brand-logo,
        .subheader,
        .offcanvas
        {
            display: none !important;

            .d-flex{
                display: none !important;
            }
        }
    }
    .aside{
        display: none !important;
    }
    #kt_wrapper{
        padding: 0 !important;
        width: 100% !important;
    }
    #kt_content{
        height: fit-content !important;
    }
    .py-15{
        padding-bottom: 0 !important;
    }
    .detail-activitas.mb-10{
        margin-bottom: 0 !important;
    }
    .print-watermark,
    .HeaderPrint {
        display: flex;
    }
    .footer-print {
        display: block;
    }
    .card {
        box-shadow: none;
        border: none;
        background-color: white;
    }
    .subheader,
    .footer,
    #kt_subheader,
    .header-mobile img,
    .MuiPaper-root {
        display: none !important;
    }

}

.print-watermark {
    display: none;
    //height: 100vh;
    //width: 100vw;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    z-index: 10000;
    img {
        opacity: 0.05;
        width: 500px;
        height: auto;
    }
}
.HeaderPrint {
    display: none;
    width: 100vw;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #6CC049;
    padding-bottom: 10px;
    margin-bottom: 5px;
    img {
        width: 100px;
    }
    p {
        margin-bottom: 4px !important;
    }
    .righContent {
        width: 40%;
        text-align: right;
        color: #6CC049;
    }
}
.footer-print {
    position: absolute;
    bottom: 0;
    margin: auto;
    width: 100vw;
    color: #6CC049;
    text-align: center;
    display: none;
}

//    formulir permohonan sertifikasi benih
.submission-header{
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    font-size: 14px;
}
.submission-body{
    padding: 220px 0 160px 0;
    font-size: 14px;
    .pagebreak{
        padding: 220px 0 150px 0;
    }
}
.submission-footer{
    width: 100%;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    font-size: 14px;
}