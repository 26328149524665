.react-datepicker-wrapper{
  width: 100%;
  &.disabled{
    .form-control{
      background-color: #F8F8F8 !important;
    }
  }
  .date-picker-custom-order{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 38px;
    position: relative;
    width: 100%;
    box-shadow: none;
    .svg-icon{
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.react-datepicker{
  border-radius: 4px;
  border: 1px solid #D1D1D1;
  .react-datepicker__header{
    background: white;
    border-bottom: 0;
    .react-datepicker__current-month, .react-datepicker__day-name{
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #353535;
      letter-spacing: 0.015em;
    }
  }
  .react-datepicker__day{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #808080;
    &.haveSchedule{
      position: relative;
      &:after{
        content: '';
        position: absolute;
        background: #BF5B04;
        left: 50%;
        transform: translateX(-50%);
        bottom: -6px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
      }
    }
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    margin: 8px;
  }
  .react-datepicker__day--weekend{
    color: #CC4435;
  }
  .react-datepicker__navigation{
    top: 14px;
  }
  .react-datepicker__day--selected{
    background: #59B134 !important;
    border-radius: 50%;
    color: white !important;
  }
  .react-datepicker__day--today{
    background: white;
    border: 1px solid #59B134;
    border-radius: 50%;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    width: 26px;
  }
  .react-datepicker__day--disabled{
    opacity: .5;
  }

}